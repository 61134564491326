<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <router-link
              :to="{ name: 'NewStudent' }"
              tag="md-button"
              class="md-just-icon md-round md-primary pull-right"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Student</md-tooltip>
            </router-link>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass: 'table',
              }"
              @onShowDetails="onShowDetails"
              @onEdit="onEdit"
              @changed="getStudents"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';

export default {
  components: {
    VueTable,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'student_number',
            mask: 'student number',
            sortable: true,
          },
          {
            title: 'first_name',
            mask: 'given name',
            sortable: true,
          },
          {
            title: 'last_name',
            mask: 'surname',
            sortable: true,
          },
          {
            title: 'email',
            mask: 'email',
            sortable: true,
          },
          {
            title: 'country.nationality',
            mask: 'nationality',
            sortable: true,
          },
          {
            title: 'biometric_saved',
            mask: 'biometric saved',
            dateTimeFormat: true,
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit Student',
            callback: 'onEdit',
            icon: 'edit',
          },
        ],
        values: {},
      },
    };
  },
  mounted() {
    this.initTable();
  },
  methods: {
    getStudents(params) {
      this.request(this.$API.STUDENTS, 'get', { ...params, paginate: true }, ({ data }) => {
        this.vTable.values = data;
      });
    },
    onShowDetails(item, isMiddleClick) {
      this.openLink({ name: 'StudentResume', params: { id: item.student_id } }, isMiddleClick);
    },
    onEdit(item, isMiddleClick) {
      this.openLink({ name: 'EditStudent', params: { id: item.student_id } }, isMiddleClick);
    },
    initTable() {
      this.$refs.vtable.init();
    },
  },
};
</script>

<style scoped></style>
